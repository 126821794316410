import { useCallback, useEffect, useState } from "react"
import { navigate } from "gatsby"
import auth from "utils/auth"
import helpers from "utils/helpers"
import message from "utils/message"
import services from "services"

import { values as formValues } from "./form"

const useFormAddCompany = () => {
  const [values] = useState(formValues)
  const [businesses, setBusinesses] = useState([])

  const user = auth.getUser()

  const fetchBusinesses = useCallback(() => {
    services.user.business({ user_id: user?.user_id }).then(setBusinesses)
  }, [user.user_id])

  const onSubmit = (formData) => {
    services.group.add(formData).then(handleAdd)
  }

  const handleAdd = async ({ error }) => {
    if (!error) {
      message({ notification: "Empresa adicionada!" })
      await helpers.wait(2000)
      return navigate(ROUTES.companies.path)
    }
  }

  useEffect(() => {
    fetchBusinesses()
  }, [fetchBusinesses])

  return {
    values,
    businesses,
    onSubmit,
  }
}

export default useFormAddCompany
