import Form from "components/Forms/Form"
import Button from "components/Button"
import Card from "components/Card"
import Switch from "components/Forms/Switch"
import Input from "components/Forms/Input"
import useFormAddCompany from "./hooks"
import maskers from "utils/maskers"

import { validation } from "./form"

import "./FormAddCompany.styl"

const FormAddCompany = () => {
  const { businesses, onSubmit } = useFormAddCompany()

  return (
    <Form
      className="form-add-company"
      onSubmit={onSubmit}
      schema={validation}
      manually={true}
    >
      {({ form, state: { isDisabled } }) => (
        <>
          <Card title="Informações da empresa" radius="medium">
            <Input
              className="form-add-user__input"
              label="Nome da empresa"
              name="name"
              data-test="company"
              placeholder="Nome"
              form={form}
            />
            <Input
              className="form-add-user__input"
              label="CNPJ"
              name="document_number"
              placeholder="Opcional"
              data-test="cnpj"
              mask={maskers.cnpj}
              form={form}
            />
          </Card>
          <Card title="Produtos" radius="medium">
            <ul
              className="form-add-company__list"
              role="group"
              aria-labelledby="checkbox-group"
            >
              {businesses.map((business) => (
                <li
                  className="form-add-company__item"
                  key={business.business_id}
                >
                  <Switch
                    legend={business.business_name}
                    name="business_ids"
                    value={String(business.business_id)}
                    form={form}
                    data-test="company-switch"
                  />
                </li>
              ))}
            </ul>
          </Card>
          <Button
            className="form-add-company__submit"
            type="submit"
            size="big"
            data-test="submit"
            disabled={isDisabled}
          >
            Salvar
          </Button>
        </>
      )}
    </Form>
  )
}

export default FormAddCompany
