import Can from "components/Can"
import PageHeader from "components/PageHeader"
import FormAddCompany from "./components/FormAddCompany"

const AddCompany = () => {
  return (
    <Can I="management" the="companies">
      <PageHeader title="Adicionar empresa" variant="small" />
      <section className="add-company">
        <FormAddCompany />
      </section>
    </Can>
  )
}

export default AddCompany
